<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import "./dxplus_release/mom-dxplus.css";
import "./css/app.css";
import "./ganalytics.js";

export default {
  name: "App",
  data() {
    return {
      createdDate: new Date(),
      firstClick: false,
    };
  },
};
</script>
