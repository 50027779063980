import Vue from "vue";
import VueRouter from "vue-router";

import routeNames from "@/constants/routeNames";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  // },
  // {
  //   path: "/DxERegistration",
  //   name: "DxERegistration",
  //   component: () =>
  //     import(/* webpackChunkName: "home" */ "../views/RegistrationForm.vue"),
  // },
  // {
  //   path: "/DxERequest",
  //   name: "DxERequest",
  //   component: () =>
  //     import(/* webpackChunkName: "home" */ "../views/RequestForm.vue"),
  // },
  // {
  //   path: "/Ack",
  //   name: "Ack",
  //   component: () => import(/* webpackChunkName: "form" */ "../views/Ack.vue"),
  // },
  // {
  //   path: "/EApproval",
  //   name: "EApproval",
  //   component: () =>
  //     import(/* webpackChunkName: "form" */ "../views/ApprovalForm.vue"),
  // },
  // {
  //   path: routeNames[404],
  //   name: "Sorry",
  //   component: () =>
  //     import(/* webpackChunkName: "form" */ "../views/Sorry.vue"),
  // },
  {
    path: "/",
    name: "Home",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/EServiceApp/LandingPage.vue"
      ),
  },
  {
    path: "/DxEService",
    name: "DxEService",
    component: () =>
      import(
        /* webpackChunkName: "RequestForm" */ "../views/EServiceApp/RequestForm.vue"
      ),
  },
  {
    path: "/Acknowledgement",
    name: "Acknowledgement",
    component: () =>
      import(
        /* webpackChunkName: "Acknowledgement" */ "../views/EServiceApp/Acknowledgement.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    document.getElementById("app").scrollIntoView();
    return null;
  },
});

export default router;
